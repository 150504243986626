import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const router = new VueRouter({
	routes: [{
			path: "/",
			redirect: "/home"
		},
		{
			path: "/home",
			component: () => import('../views/home/home.vue')
		},
		{
			path: "/product",
			component: () => import('../views/product/product.vue')
		},
		{
			path: "/aboutmine",
			component: () => import('../views/aboutmine/aboutmine.vue')
		},
		{
			path: "/contactus",
			component: () => import('../views/contactus/contactus.vue')
		},
		{
			path: "/panorama",
			component: () => import('../views/panorama/panorama.vue')
		},
		{
			path: "/newsCenter",
			component: () => import('../views/newsCenter/newsCenter.vue')
		},
		{
			path: "/newsDetail",
			component: () => import('../views/newsCenter/newsDetail.vue')
		},
		{
			path: "/login",
			component: () => import('../views/login/login.vue')
		},
		{
			path: "/productDetail",
			component: () => import('../views/product/productDetail.vue')
		},
	]
})


export default router;